<template>
  <div class="mx-auto" style="max-width: 400px;">
    <span class="title">Authorization</span>
    <v-text-field
      v-model="authLogin"
      outlined dense hide-details
      label="Login"
      class="my-4"
    />
    <v-text-field
      v-model="authPassword"
      :type="isShowPassword ? 'text' : 'password'"
      outlined dense hide-details
      label="Password"
      :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="isShowPassword = !isShowPassword"
      class="my-4"
    />
    <v-btn depressed block
           :disabled="isDisabledAuth"
           @click="auth"
           :loading="$store.AppStore.isAuthLoading"
    >
      login
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'Login',
  computed: {
    authLogin: {
      get() {
        return this.$api.main.getAuthLogin();
      },
      set(...args) {
        return this.$api.main.setAuthLogin(...args);
      },
    },
    authPassword: {
      get() {
        return this.$api.main.getAuthPassword();
      },
      set(...args) {
        return this.$api.main.setAuthPassword(...args);
      },
    },
    isDisabledAuth() {
      return this.$api.main.isDisabledAuth();
    },
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  methods: {
    auth(...args) {
      return this.$api.main.auth(...args);
    },
  },
};
</script>
